import * as monaco from 'monaco-editor'
import { Resizable } from 're-resizable'
import React, { useEffect, useRef, useState } from 'react'
import {
  generateArchGoDiagram,
  generateArchitectureDiagramFn,
  generateAvroSchemaDiagram,
  generateBrainstormDiagram,
  generateDockerComposeDiagram,
  generateInventoryGrapherDiagram,
  generatePlaybookGrapherDiagram,
  generateProtobufDiagram,
  generateTerraformDiagram,
  handleDeleteFn,
} from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import DiagramSide from './DiagramSide'
import EditorSide from './EditorSide'
import UploadModal from '../modals/UploadModal'
import SaveModal from '../modals/SaveModal'
import { generateSwaggerToUML } from '../diagrams/SwaggerToUml'
import { generateUML } from '../diagrams/PlantUml'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModal from '../modals/DeleteModal'
import PaymentModal from '../modals/PaymentModal'


const AppContent = ({
  monacoRef,
  uploadThreshold,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  getAccessTokenSilently,
  setUploading,
  setShowUpload,
  handleOptionChange,
  selectedCode,
  codeByFileName,
  diagramName,
  setImageSrc,
  direction,
  isAuthenticated,
  saveCodeApi,
  selectedOptionAuto,
  appendCode,
  imageSrc,
  setHistory,
  history,
  setSelectedOptionAuto,
  setCodeByFileName,
  showUpload,
  uploading,
  handleUpgrade,
  showPaymentConfirm,
  setShowPaymentConfirm,
  filename,
  setFilename,
  setDirection,
  setSelectedEmail,
  editorState,
  setVisibleShare,
  openSnackbarDiagram,
}) => {
  const dispatch = useDispatch()
  const intervalRef = useRef(null);
  const previousCodeRef = useRef('');
  const [visibleSave, setVisibleSave] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [visibleLoading, setVisibleLoading] = useState(false)
  const [pythonError, setPythonError] = useState('Internal Server Error')
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const showDiagram = useSelector((state) => state.showDiagram)
  let inventoryCode = useSelector((state) => state.inventoryGrapher.inventoryGrapherEditorValue)
  let protobufEditorCode = useSelector((state) => state.protobufEditor.protobufEditorValue)
  let avroEditorCode = useSelector((state) => state.avroSchemaEditor.avroSchemaEditorValue)
  let dockerCode = useSelector((state) => state.dockerComposeEditor.dockerComposeEditorValue)
  let selectedMode = useSelector((state) => state.stateReducer.selectModeValue)
  let archGoEditorCode = useSelector((state) => state.archGoEditor.archGoEditorValue)
  let D2EditorCode = useSelector((state) => state.brainstormEditor.d2EditorValue)
  let playbookGrapherCode = useSelector((state) => state.playbookGrapher.playbookGrapherEditorValue)
  let terraFormCode = useSelector((state) => state.terraformEditor.terraformEditorValue)
  let architectureCode = useSelector((state) => state.architectureEditor.architectureEditorValue)


  useEffect(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Reset the reference
    }

    if(selectedMode==CONSTANTS.JSON || selectedMode==CONSTANTS.YAML || selectedMode== CONSTANTS.ASCII ||  selectedMode== CONSTANTS.SWAGGER){

        intervalRef.current = setInterval(function umlAutoExecute(){
          const editorCode = monacoRef.current?.getValue(); 
          if (previousCodeRef.current.trim() !== editorCode.trim()) {
            previousCodeRef.current= editorCode
            handleExecute(false)
          } 
        }, 5000);
    } 
  }, [selectedMode]);

  const handleEditorDrop = (e) => {
    e.preventDefault()
    const droppedText = e.dataTransfer.getData('text/plain')
    const position = monacoRef.current.getTargetAtClientPoint(e.clientX, e.clientY)
    const { lineNumber, column } = position.position

    var range = new monaco.Range(lineNumber, column, lineNumber, column)
    if (
      droppedText.includes('with') ||
      droppedText.includes('Make') ||
      droppedText.includes('make') ||
      droppedText.includes('DIAGRAM_NAME') ||
      droppedText.includes('ORIENTATION')
    ) {
      monacoRef.current.executeEdits('', [
        { range: monacoRef.current.getModel().getFullModelRange(), text: '' },
      ])
    }
    appendCode(droppedText, range)
  }

  const handleEditorDragOver = (e) => {
    e.preventDefault()
  }

  const setVisibleLoader = (visible) => {
    setOpenSnackbarDiagram(visible)
    setVisibleLoading(visible)
  }

  async function handleDelete() {
    const token = await getAccessTokenSilently()
    setSnackbarMessage('Deleting file...')
    setSnackbarSeverity('info')
    setOpenSnackbar(true)
    handleDeleteFn(selectedOptionAuto, token, setHistory, setCodeByFileName, function (resp) {
      sessionStorage.setItem('FILE_DELETED', 'TRUE')
      setSnackbarMessage('File deleted successfully!')
      setSnackbarSeverity('success')
      setOpenSnackbar(true)
      setShowDeleteConfirmation(false)
      setSelectedOptionAuto(CONSTANTS.DEFAULTFILENAME)
      if (sessionStorage.getItem('filenamesList')) {
        if (selectedOptionAuto === localStorage.getItem('SELECTEDFILE')) {
          localStorage.setItem('SELECTEDFILE', CONSTANTS.DEFAULTFILENAME)
          setSelectedOptionAuto(CONSTANTS.DEFAULTFILENAME)
          selectedCode(null)
        }
      }
    })
  }

  async function generate(isDownload, diagram_name) {
    var code = monacoRef.current.getValue()
    if (!code && code === '') {
      setImageSrc('')
      return
    }

    let orientation = 'TB'
    if (localStorage.getItem('ORIENTATIONS')) {
      orientation = localStorage.getItem('ORIENTATIONS')
    }

    if (selectedMode === CONSTANTS.ARCHITECTURE_DIAGRAM) {
      setOpenSnackbarDiagram(true)
      generateArchitectureDiagramFn(
        architectureCode,
        isDownload,
        null,
        null,
        setImageSrc,
        setPythonError,
        setSnackbarMessage,
        setSnackbarSeverity,
        setOpenSnackbar,
        setOpenSnackbarDiagram,
        diagramName,
        direction,
        setVisibleLoading,
        function (resp) {
          if (resp && resp.data.result.diagram)
            localStorage.setItem('LASTGENERATEDIMAGE', resp.data.result.diagram)
          setOpenSnackbarDiagram(false)
        },
      )
    } else if (selectedMode === CONSTANTS.SWAGGER) {
      setVisibleLoading(true)
      generateSwaggerToUML(
        code,
        isDownload,
        setImageSrc,
        setSnackbarMessage,
        setSnackbarSeverity,
        setOpenSnackbar,
        setVisibleLoading,
        setOpenSnackbarDiagram,
      )
    } else if (selectedMode === CONSTANTS.JSON || selectedMode === CONSTANTS.YAML  || selectedMode === CONSTANTS.ASCII ) {
      setVisibleLoading(true)
      generateUML(
        code,
        isDownload,
        selectedMode,
        setImageSrc,
        setSnackbarMessage,
        setSnackbarSeverity,
        setOpenSnackbar,
        setVisibleLoading,
        setOpenSnackbarDiagram,
      )
    }
  }


  
    
  const handleClose = () => {
    setIsDialogOpen(false); 
  };

  async function handleExecute(isDownload, diagram_name) {
    const editorCode = monacoRef.current?.getValue();
  
    if (!editorCode || editorCode.trim() === '') {
      setIsDialogOpen(true);
      return; 
    }

    if (selectedMode === CONSTANTS.PLAYBOOK_GRAPHER) {
      setOpenSnackbarDiagram(true)
      const image = await generatePlaybookGrapherDiagram(playbookGrapherCode, isDownload)
      localStorage.setItem('PlaybookGrapher_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.TERRAFORM) {
      setOpenSnackbarDiagram(true)
      const image = await generateTerraformDiagram(terraFormCode, isDownload)
      localStorage.setItem('TERRAFORM_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.D2) {
      setOpenSnackbarDiagram(true)
      const image = await generateBrainstormDiagram(D2EditorCode, isDownload)
      localStorage.setItem('D2_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.BRAINSTORM) {
      setOpenSnackbarDiagram(true)
      const image = await generateBrainstormDiagram(
        sessionStorage.getItem('BRAINSTORM_COMPILED_CODE'),
        isDownload,
      )
      localStorage.setItem('BRAINSTORM_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.PROTOBUF) {
      setOpenSnackbarDiagram(true)
      const image = await generateProtobufDiagram(protobufEditorCode, isDownload)
      localStorage.setItem('PROTOBUF_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.AVROSCHEMA) {
      setOpenSnackbarDiagram(true)
      const image = await generateAvroSchemaDiagram(avroEditorCode, isDownload)
      localStorage.setItem('AVROSCHEMA_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.DOCKERCOMPOSE) {
      setOpenSnackbarDiagram(true)
      const image = await generateDockerComposeDiagram(dockerCode, isDownload)
      localStorage.setItem('DOCKER_COMPOSE_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.ARCHGO) {
      let jsonData = {
        code: archGoEditorCode,
        orientation: direction,
        diagramName: diagramName,
      }
      setOpenSnackbarDiagram(true)
      const image = await generateArchGoDiagram(jsonData, isDownload)
      localStorage.setItem('ARCHGO_IMG', image)
      setOpenSnackbarDiagram(false)
    } else if (selectedMode === CONSTANTS.INVENTORY_GRAPHER) {
      setOpenSnackbarDiagram(true)
      const image = await generateInventoryGrapherDiagram(inventoryCode, isDownload)
      localStorage.setItem('INVENTORY_IMG_DOWNLOAD', image)
      setOpenSnackbarDiagram(false)
    } else {
      generate(isDownload, diagram_name)
    }
  }

  const handleUndo = () => {
    if (monacoRef.current) {
      const model = monacoRef.current.getModel()
      if (model) {
        model.undo()
      }
    }
  }
  const handleRedo = () => {
    if (monacoRef.current) {
      const model = monacoRef.current.getModel()
      if (model) {
        model.redo()
      }
    }
  }

  const getDefaultSize = () => {
    const width = window.innerWidth

    if (width <= 480) {
      // For small screens (mobile)
      return { width: '50%', height: 'auto' }
    } else if (width <= 768) {
      // For medium screens (tablets)
      return { width: '50%', height: 'auto' }
    } else {
      // For larger screens (desktop)
      return { width: 'auto', height: 'auto' }
    }
  }

  const [defaultSize, setDefaultSize] = useState(getDefaultSize())

  useEffect(() => {
    const handleResize = () => {
      setDefaultSize(getDefaultSize())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="d-flex" onDrop={handleEditorDrop} onDragOver={handleEditorDragOver}>
      {showDiagram && (
        <Resizable defaultSize={defaultSize} enable={{ right: true }}>
          <div style={{ position: 'sticky', overflow: 'hidden' }}>
            <EditorSide
              monacoRef={monacoRef}
              setVisibleLoading={setVisibleLoader}
              setImageSrc={setImageSrc}
              appendCode={appendCode}
              setSnackbarMessage={setSnackbarMessage}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarSeverity={setSnackbarSeverity}
              setOpenSnackbarDiagram={setOpenSnackbarDiagram}
              handleOptionChange={handleOptionChange}
              selectedCode={selectedCode}
              codeByFileName={codeByFileName}
              isAuthenticated={isAuthenticated}
              setVisibleSave={setVisibleSave}
              saveCodeApi={saveCodeApi}
              selectedOptionAuto={selectedOptionAuto}
              history={history}
              setShowDeleteConfirmation={setShowDeleteConfirmation}
              editorState={editorState}
              setPythonError={setPythonError}
              pythonError={pythonError}
              handleExecute={handleExecute}
              handleUndo={handleUndo}
              handleRedo={handleRedo}
              isDialogOpen={isDialogOpen}
              handleClose={handleClose}
              direction={direction}
              setDirection={setDirection}
            />
          </div>
        </Resizable>
      )}
      <DiagramSide
        monacoRef={monacoRef}
        imageSrc={imageSrc}
        setDirection={setDirection}
        direction={direction}
        visibleLoading={visibleLoading}
        dispatch={dispatch}
        setSelectedEmail={setSelectedEmail}
        setVisibleShare={setVisibleShare}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setOpenSnackbar={setOpenSnackbar}
        handleExecute={handleExecute}
        setVisibleLoading={setVisibleLoading}
        setPythonError={setPythonError}
        archDiagram={diagramName}
        setOpenSnackbarDiagram={setOpenSnackbarDiagram}
        isAuthenticated={isAuthenticated}
        opemSnackbarDiagram={openSnackbarDiagram}
        // handleUndo={handleUndo}
        // handleRedo={handleRedo}
      />
      <UploadModal
        uploadThreshold={uploadThreshold}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setOpenSnackbar={setOpenSnackbar}
        getAccessTokenSilently={getAccessTokenSilently}
        setUploading={setUploading}
        setShowUpload={setShowUpload}
        showUpload={showUpload}
        uploading={uploading}
        handleUpgrade={handleUpgrade}
      />
      <PaymentModal
        showPaymentConfirm={showPaymentConfirm}
        setShowPaymentConfirm={setShowPaymentConfirm}
      />
    
   

     

      <SaveModal
        visibleSave={visibleSave}
        setVisibleSave={setVisibleSave}
        setFilename={setFilename}
        saveCodeApi={saveCodeApi}
        filename={filename}
        monacoRef={monacoRef} 
      />
      <DeleteModal
        showDeleteConfirmation={showDeleteConfirmation}
        setShowDeleteConfirmation={setShowDeleteConfirmation}
        handleDelete={handleDelete}
      />
    </div>
  )
}

export default AppContent
